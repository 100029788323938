<template>
  <div>

    <div class="navbar">

      <div class="logo">
        <img src="@/assets/logo.png"
             alt="logo"
             style="" />

        <p class="logo-text"
           style="">XTab新标签页</p>
      </div>

      <router-link v-for="(item,index) in list"
                   :key="index"
                   :to="item.path"
                   @click.native="click(index)"
                   :class="{active: selectIndex === index}">
        <p style="">{{item.title}}</p>
      </router-link>

      <!-- <div class="web_btn"
           @click="openWeb">
        <p>网页版</p>
      </div> -->

      <div class="web_btn"
           @click="openWeb">
        <span>网页版</span>
      </div>

    </div>

    <!-- 添加一个占位元素 -->
    <div class="navbar-placeholder"></div>

  </div>
</template>

<script>

export default {
  name: 'Navbar',
  components: {},
  props: {},
  data () {
    //这里存放数据
    return {
      selectIndex: 0,
      list: [
        {
          title: '首页',
          path: '/'
        },
        {
          title: '小组件',
          path: '/component'
        },
        {
          title: '下载',
          path: '/download'
        },
        {
          title: '安装指南',
          path: '/installationGuide'
        },
        {
          title: '关于',
          path: '/about'
        },

      ],
      lastScrollTime: null,
      endX: 0,
      endY: 0,
      startX: 0,
      startY: 0,

    };
  },
  watch: {
    '$route' (val) {
      //   console.log('路由切换:', val.path);
      this.selectIndex = this.list.findIndex(item => item.path === val.path);
    }
  },
  methods: {
    openWeb () {
      window.open('https://go.xtab.link/')
    },
    click (index) {

      this.selectIndex = index;
    },
    handleWheel (event) {
      const currentTime = Date.now();
      if (currentTime - this.lastScrollTime < 500) {
        // 处理滚轮事件的代码
        // console.log('滚动事件触发了一次');
        return
      }

      this.lastScrollTime = currentTime;

      // deltaY 可以获取滚轮滚动的方向和速度
      const deltaY = event.deltaY;
      // 根据 deltaY 的值判断滚轮滚动的方向
      if (deltaY > 0) {
        // 向下滚动
        // console.log('向下滚动');
        // 在这里可以执行滚动向下时的逻辑

        if (this.selectIndex < this.list.length - 1) {
          this.selectIndex++
          if (this.$route.path !== this.list[this.selectIndex].path) {
            this.$router.push(this.list[this.selectIndex].path)
          }
        }

      } else if (deltaY < 0) {
        // 向上滚动s

        if (this.selectIndex > 0) {
          this.selectIndex--
          if (this.$route.path !== this.list[this.selectIndex].path) {
            this.$router.push(this.list[this.selectIndex].path)
          }
        }
      }


    },
    handleSwipe () {

      //   console.log('this.$route.path:', this.$route.path);
      const currentTime = Date.now();
      if (currentTime - this.lastScrollTime < 500) {
        // 如果滚动事件太频繁，忽略这次事件
        return;
      }
      this.lastScrollTime = currentTime;

      const swipeThreshold = 100; // 定义滑动的阈值，可以根据实际情况调整
      const verticalSwipeThreshold = 30; // 定义垂直滑动的阈值，可以根据实际情况调整

      const xDiff = this.endX - this.startX;
      const yDiff = this.endY - this.startY;
      //   console.log('Math.abs(yDiff):', yDiff, Math.abs(yDiff));


      if (Math.abs(xDiff) > swipeThreshold) {
        // 水平滑动
        if (xDiff > 0) {
          // 右滑动作
          if (this.selectIndex > 0) {
            this.selectIndex--;
            if (this.$route.path !== this.list[this.selectIndex].path) {
              this.$router.push(this.list[this.selectIndex].path);
            }
          }
        } else {
          // 左滑动作
          if (this.selectIndex < this.list.length - 1) {
            this.selectIndex++;
            if (this.$route.path !== this.list[this.selectIndex].path) {
              this.$router.push(this.list[this.selectIndex].path);
            }
          }
        }
      } else if (Math.abs(yDiff) > verticalSwipeThreshold) {
        // 垂直滑动
        if (yDiff > 0) {
          // 下滑动作
          //   console.log("下滑动作");
          // 在这里添加下滑后的逻辑
        } else {
          // 上滑动作
          console.log("上滑动作");
          // 在这里添加上滑后的逻辑

          //   if (this.$route.path != "/" && this.$route.path != "/installationGuide" && this.$route.path != "/about") {
          //     // 左滑动作
          //     if (this.selectIndex < this.list.length - 1) {
          //       this.selectIndex++;
          //       if (this.$route.path !== this.list[this.selectIndex].path) {
          //         this.$router.push(this.list[this.selectIndex].path);
          //       }
          //     }
          //   }

        }
      }
    },



    handleScroll () {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop + windowHeight >= documentHeight) {
        console.log('页面触底了');
        // 这里可以添加触底后的逻辑
        // || this.$route.path == "/installationGuide" this.$route.path == "/" ||
        if (this.$route.path == "/about") {
          // 左滑动作
          if (this.selectIndex < this.list.length - 1) {
            this.selectIndex++;
            if (this.$route.path !== this.list[this.selectIndex].path) {
              this.$router.push(this.list[this.selectIndex].path);
            }
          }
        }

      }
    }

  },
  mounted () {


    this.selectIndex = this.list.findIndex(item => item.path === this.$route.path);
    document.body.addEventListener('wheel', this.handleWheel);
    document.addEventListener('touchstart', (event) => {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    });
    document.addEventListener('touchend', (event) => {
      this.endX = event.changedTouches[0].clientX;
      this.endY = event.changedTouches[0].clientY;
      this.handleSwipe();
    });

    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped >
/* 媒体查询，适用于小于或等于768像素宽度的设备（典型的手机端） */
@media (max-width: 768px) {
  .navbar {
    overflow: hidden;
    background-color: #33333375;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* 使导航栏在其他内容之上 */
    height: 53px;
    right: 0; /* 将导航栏靠右对齐 */
  }

  .logo {
    margin: 14px 0 0 15px;
    float: left;
  }
  .logo p {
    display: none;
  }
  .logo img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
  .navbar a {
    /* float: right; */
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 0px 5px;
    text-decoration: none;
    font-size: 15px;
  }
  .navbar a:hover {
    /* background-color: #ddd; */
    /* color: rgb(153, 204, 255);s */
  }

  .navbar-placeholder {
    /* height: 48px;  */
  }

  .active {
    /* color: #409eff !important; */
  }
  .active p {
    padding-bottom: 5px;
    border-bottom: 2px solid rgb(237, 152, 41) !important;
  }

  .web_btn {
    cursor: pointer;
    color: white;
    margin: 10px 5px;
    font-size: 12px;
    color: white;
    width: 60px;
    height: 26px;
    background: rgb(42, 119, 196);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    text-align: center;
    border-radius: 5px;

    position: absolute;
    right: 5px;
    top: 3px;
  }
  .web_btn:hover {
    /* color: rgb(237, 152, 41); */
  }
}
@media (min-width: 769px) {
  .navbar {
    overflow: hidden;
    background-color: #33333375;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* 使导航栏在其他内容之上 */
    height: 53px;
    right: 0; /* 将导航栏靠右对齐 */
  }

  .logo {
    display: flex;
    align-items: center;
    position: relative;
    left: 30px;
    top: 14px;
    float: left;
    margin-right: 80px;
  }
  .logo-text {
    margin: 0;
    color: white;
    font-size: 16px;
  }

  .logo img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
  .navbar a {
    /* float: right; */
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 0px 20px;
    text-decoration: none;
    font-size: 15px;
  }
  .navbar a:hover {
    /* background-color: #ddd; */
    /* color: rgb(153, 204, 255); */
  }

  .navbar-placeholder {
    /* height: 48px;  */
  }

  .active {
    /* color: #409eff !important; */
  }
  .active p {
    padding-bottom: 5px;
    border-bottom: 2px solid rgb(237, 152, 41) !important;
  }
  .web_btn {
    cursor: pointer;
    color: white;
    float: right;
    /* padding-right: 30px; */
    margin: 15px 30px 0 0;
    font-size: 12px;
    color: white;
    width: 100px;
    height: 27px;
    background: rgb(42, 119, 196);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    text-align: center;
    line-height: 18px;
    border-radius: 5px;
  }
  .web_btn:hover {
    transform: scale(1.05);
  }
}
</style>
