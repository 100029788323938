<template>
  <div class="home">
    <div class="left-third">
      <transition name="text-slide"
                  mode="out-in">
        <div class="left-content"
             v-if="showText">
          <h1 class="title">

            {{list[index].title}}
          </h1>

          <p>{{list[index].desc}}</p>

          <div class="btn-box">
            <div class="btn_main one"
                 @click="$router.push('/download')">
              <span>安装插件</span>
            </div>
            <div class="btn_main two"
                 style="left: 180px;top: -44px;"
                 @click="openWeb">
              <span>快速体验</span>
            </div>
          </div>

        </div>
      </transition>
    </div>
    <div class="right-two-thirds">
      <div class="buttom last"
           alt="按钮"
           @click="last" />

      <transition name="image-fade"
                  mode="out-in">
        <div v-show="showImage"
             key="about-image"
             class="image-box">

          <img :src="list[index].img"
               class="image-border" />
        </div>
      </transition>

      <div class="buttom next"
           alt="按钮"
           @click="next" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'componentx',
  data () {
    return {
      // img: require('@/assets/img/home.png')
      list: [
        {
          title: "极简模式",
          desc: "简化界面，集中核心功能，提升操作效率和用户体验",
          img: 'https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/极简模式.png'
        },
        {
          title: "游戏市场",
          desc: "轻松便捷的游戏体验，通过网页即可随时享受，适合休闲娱乐。",
          //   img: 'https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/game.png',
          img: require('@/assets/img/game.png'),
        },
        {
          title: "网络热搜",
          desc: "反映当下社会关注度和舆论热点的网络话题，通常涵盖新闻事件、娱乐八卦和社会议题等多方面内容。",
          //   img: 'https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/hot.png',
          img: require('@/assets/img/hot.png'),
        },

        {
          title: "电影推荐",
          desc: "每天为你精选一部电影，带来不同题材和风格的精彩观影体验。",
          img: 'https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/电影.png'
        },
        {
          title: "天气",
          desc: "提供实时和预测天气信息，帮助用户随时了解目标地点的天气情况",
          img: 'https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/天气.png'
        },
        {
          title: "等等....",
          desc: "欢迎自行探索更多功能。",
          img: "https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/home.png"
        },




        {
          title: "",
          desc: "",
          img: ""
        },


      ],
      index: 0,



      showText: false,
      showImage: false,
      autoTimer: null
    };
  },
  watch: {
    '$route' () {
      this.triggerAnimation();
    }
  },
  mounted () {
    this.getList()

    this.triggerAnimation();
    this.autoTimer = setInterval(() => {
      this.next("auto")
    }, 3000);



    let ccc = [
      {
        "title": "电影推荐",
        "desc": "每天会推荐好的电影",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/电影推荐.png"
      },
      {
        "title": "自定义vue",
        "desc": "这是一个可以自己编写vue代码的组件",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/自定义vue.png"
      },
      {
        "title": "下班倒计时",
        "desc": "实时查看下班还有多少时间，今天赚了多少钱",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/下班倒计时.png"
      },
      {
        "title": "换算器",
        "desc": "\"换算器\"是一个实用的工具，能够快速进行单位、货币或其他类型的转换，帮助用户简化复杂的计算过程。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/换算器.png"
      },
      {
        "title": "历史上的今天",
        "desc": "用于回顾和展示某一天在历史上发生的重大事件、著名人物的生日或逝世等重要信息。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/历史上的今天.png"
      },
      {
        "title": "今年余额",
        "desc": "\"今年余额\"是一款简单实用的工具，它帮助用户追踪当前年份剩余的天数，使他们能够更好地规划和管理时间。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/今年余额.png"
      },
      {
        "title": "每日一言",
        "desc": "\"每日一言\"是一款灵感励志类应用，每天为用户提供一句鼓舞人心的格言或名言，旨在激励和启发他们的日常生活。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/每日一言.png"
      },
      {
        "title": "节日",
        "desc": "\"节日\"是一个便捷的节日日历应用，提供节日日期信息，让用户轻松了解和规划重要的节庆活动",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/节日.png"
      },
      {
        "title": "生日",
        "desc": "\"生日\"是一款专注于帮助用户记录和管理重要生日的应用程序，使他们能够及时发送祝福、设置提醒，并且轻松地查看亲朋好友的生日信息。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/生日.png"
      },
      {
        "title": "电子木鱼",
        "desc": "电子木鱼是一种现代化的佛教法器，通常用于替代传统的手持木鱼。它利用电子技术和音频设备模拟木鱼的敲击声音，用于帮助修行者进行念佛、诵经或冥想等修行活动",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/电子木鱼.png"
      },

      {
        "title": "便民查询",
        "desc": "便民查询网",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/便民查询.png"
      },

      {
        "title": "世界时钟",
        "desc": "世界时钟是一种显示不同时区时间的组件，有助于人们跨时区沟通和协调工作、生活安排。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/世界时钟.png"
      },
      {
        "title": "习惯养成",
        "desc": "习惯养成是通过重复行为和自律塑造的良好习惯，对个人成长和成功至关重要。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/习惯养成.png"
      },
      {
        "title": "翻页时钟",
        "desc": "翻页时钟（Flip Clock）是一种特殊设计的时钟，其显示时间的方式类似于旧式的数字时钟，使用带有数字板片的翻页装置来显示小时和分钟。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/翻页时钟.png"
      },

      {
        "title": "财经日历",
        "desc": "财经日历是一种工具，用于提供即将发生或已经发生的重要经济事件和数据发布时间表。这些事件和数据通常对全球金融市场产生重大影响，因此财经日历对投资者、交易者和经济分析师具有重要意义。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/财经日历.png"
      },
      {
        "title": "网络测速",
        "desc": "在线网络测速工具",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/网络测速.png"
      },
      {
        "title": "IP 地址查询",
        "desc": "在线IP地址查询工具",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/IP 地址查询.png"
      },

      {
        "title": "AI市场",
        "desc": "AI市场是指涉及人工智能技术、产品和服务的商业领域，呈现出快速增长和广阔的发展潜力。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/AI市场.png"
      },
      {
        "title": "精选网站",
        "desc": "精选网站是经过筛选和推荐，具有特定主题或特色的优质网站集合。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/精选网站.png"
      },
      {
        "title": "数据导入",
        "desc": "可以方便的导入浏览器，或第三方系统的数据",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/数据导入.png"
      },
      {
        "title": "组件商店",
        "desc": "系统的组件都在这里下载使用",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/组件商店.png"
      },
      {
        "title": "新手教程",
        "desc": "里面有大量的关于如果使用XTab的教程",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/新手教程.png"
      },
      {
        "title": "分组市场",
        "desc": "系统整理了大理优秀的网站资源",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/分组市场.png"
      },
      {
        "title": "XTab浏览历史记录",
        "desc": "这个组件会显示xtab的浏览历史记录",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/XTab浏览历史记录.png"
      },

      {
        "title": "纪念日",
        "desc": "\"纪念日\"是一款方便记录和庆祝个人重要日子的应用，让用户可以轻松管理和提醒重要纪念日，如结婚纪念日、恋爱纪念日等。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/纪念日.png"
      },

      {
        "title": "主题市场",
        "desc": "系统提供大量精美的主题，您可以自由选择使用。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/主题市场.png"
      },
      {
        "title": "日历组件",
        "desc": "日历组件用于显示和管理日期和时间信息。它可以作为应用程序或网站的一部分，用于用户选择日期、查看日程安排、预订时间等功能。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/日历组件.png"
      },
      {
        "title": "记事本",
        "desc": "\"记事本\"是一款简单而实用的应用程序，用于快速记录和管理用户的笔记、待办事项和其他重要信息。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/记事本.png"
      },
      {
        "title": "待办",
        "desc": "\"待办\"是一款专注于帮助用户管理任务和计划的应用，让用户可以轻松列出、安排和完成日常任务和目标。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/待办.png"
      },
      {
        "title": "网络热搜",
        "desc": "\"网络热搜\"是一个提供即时热门话题和事件的平台，帮助用户快速了解和探索当前社会和媒体上最受关注的内容。",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/网络热搜.png"
      },
      {
        "title": "游戏市场",
        "desc": "大量好玩的在线网页小游戏",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/游戏市场.png"
      },
      {
        "title": "天气",
        "desc": "用于提供当前和未来几天特定地区的天气情况，包括温度、湿度、风速等信息，帮助用户做出合理的生活和旅行安排",
        "img": "https://xgfile-web.oss-cn-beijing.aliyuncs.com/componentImg/天气.png"
      }
    ]

    // this.list = ccc

  },
  methods: {

    getList () {

      fetch('https://api.xtab.link:443/api/public/DataReceive/getAnIntroductionToWebComponents', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {

          let list = JSON.parse(data.data[0].value)
          //   console.log('list:', list);
          if (list.length > 0) {
            this.list = list
          }

        })
        .catch(error => console.error('Error:', error));


    },


    openWeb () {
      window.open('https://go.xtab.link/')
    },
    last () {

      // 
      if (this.index > 0) {
        this.index--;
      } else {
        this.index = this.list.length - 1;
      }

    },
    next (type) {

      if (type != "auto") {

        clearInterval(this.autoTimer);
        this.autoTimer = null
      }

      if (this.index < this.list.length - 1) {

        this.index++;
      } else {
        this.index = 0;

      }

    },
    triggerAnimation () {
      this.showText = false;
      this.showImage = false;
      setTimeout(() => {
        this.showText = true;
        this.showImage = true;
      }, 100);
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .home {
    height: 100vh;

    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg1.png');
    background-size: cover;
  }

  .left-third {
    justify-content: center;
    align-items: center;
    position: relative;
    top: 100px;
  }

  .right-two-thirds {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 150px;
  }

  .buttom {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .buttom:hover {
    box-shadow: 0 0 10px #409eff;
  }

  .buttom.last {
    transform: scale(0.5);
  }

  .buttom.next {
    transform: scale(0.5) rotate(180deg);
  }

  .left-content {
    transition: transform 1s ease;
    padding: 0 40px;
    /* text-align: left; */
    color: white;
  }

  .title {
    font-family: 'SHS-B';
    font-size: 32px;
    font-weight: bold;
    color: rgb(204, 118, 1);
    display: inline-block;
  }
  .left-content p {
    height: 75px;
  }

  .image-box {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-border {
    /* width: 100%;
  height: auto; */

    width: 100%;
    height: auto;

    transition: opacity 1s ease-in-out;
    border-radius: 15px;
  }

  .image-inner {
    position: absolute;
    width: 76%; /* 这里可以调整内部图片大小 */
    height: auto;
    top: 37.4%;
    left: 50.4%;
    transform: translate(-50%, -50%); /* 将图片居中 */
    transition: opacity 1s ease-in-out;
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 1s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 1s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }
}
@media (min-width: 769px) {
  .home {
    display: flex;
    height: 100vh;

    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg1.png');
    background-size: cover;
  }

  .left-third {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-two-thirds {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
  }

  .buttom {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url(@/assets/img/arrow.png) no-repeat center center
      rgb(70, 116, 230);
    background-size: 20px 20px;
  }
  .buttom:hover {
    box-shadow: 0 0 10px #409eff;
  }

  .buttom.last {
    transform: scale(0.5);
  }

  .buttom.next {
    transform: scale(0.5) rotate(180deg);
  }

  .left-content {
    transition: transform 1s ease;
    padding: 0 40px;
    text-align: left;
    color: white;
    width: 300px;
  }

  .title {
    font-family: 'SHS-B';
    font-size: 32px;
    font-weight: bold;
    color: rgb(204, 118, 1);
  }

  .image-box {
    position: relative;
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-border {
    /* width: 100%;
  height: auto; */

    width: 700px;
    height: auto;

    transition: opacity 1s ease-in-out;
    border-radius: 15px;
  }

  .image-inner {
    position: absolute;
    width: 76%; /* 这里可以调整内部图片大小 */
    height: auto;
    top: 37.4%;
    left: 50.4%;
    transform: translate(-50%, -50%); /* 将图片居中 */
    transition: opacity 1s ease-in-out;
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 1s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 1s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }
}
</style>
